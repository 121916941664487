@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

* {
  transition: all 0.1s ease-in-out;
}
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.green {
  background-color: green !important;  
  color: white;
}

.red {
  background-color: #d63031 !important;
  color: white;
}

.yellow {
  background-color: orange !important;
  color: white;
}

.MuiPickersPopper-root, .MuiPopover-root {
  z-index: 1000000000 !important;
}

.title {
  color: #42ffcd ;
  font-size: 16px;
}

 .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.css-1ptyyyq-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: white !important;
}

.form-row {
  display: flex;
  gap: 10px;
  width: 100%;
}

.pro-menu-item {
  padding: 2px 6px;
  z-index: 9000000000000000000000 !important;
}

.w-fit {
  width: 100% !important;
  text-align: center;
  padding: 0 6px;
  color: white !important;
  font-weight: 800;
  display: flex;
  justify-content: center;
}

.MuiMenu-list {
  z-index: 9000000000000000000000 !important;
}

.form-row2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 50%;
}
.form-group2 {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.form-input {
  width: 100%;
  background-color: #2d3436;
  color: white;
  border: 1px solid #2d3436;
  padding: 8px 16px;
  font-size: 16px;
  outline: none;
  border-radius: 5px;
}
.form-input2 {
  width: 100%;
  background-color: #323848;
  color: white;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  padding: 8px 16px;
  font-size: 16px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.form-label {
  color: white;
  font-size: 12px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.internal-cell {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.swal2-container {
  z-index: 900000000000000000000 !important;
}

/* .MuiInputBase-root {
  width: 100% !important;
} */

.w-full {
  width: 100% !important;
}

.name-column--cell--center {
  text-align: center !important;
  justify-content: center !important; 
  width: 100% !important;
}

.content {
  overflow-y: scroll;
}

.date {
  border: 1px solid white !important;
  margin-right: 16px !important;
  height: 48px;
  width: 160px;
}

.MuiInputLabel-animated {
  background-color: #141b2d;
  color: white;
}

.MuiInputLabel-root {
  color: white !important;
}

.relative {
  position: relative;
}

.label_id {
  display: none;
}

.bx {
  cursor: pointer;
}

.relative:hover .label_id {
  display: flex;
  position: absolute;
  top: -32px;
  width: 106px;
  background-color: #30457b;
  padding: 2px 6px;
}

/* Table row color */
.super-app-theme--1 {
  background-color: #3c585b !important;
}