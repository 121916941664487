.fade-in {
  animation: scale-in 0.5s forwards;
}

.fade-out {
  animation: scale-out 0.5s forwards;
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(100%);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(100%);
  }

  100% {
    transform: scale(0%);
  }
}

.modal {
  display: flex;
  inset: 0;
  position: fixed;
  /* z-index: 50; */
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.modalBody {
  /* bg-white relative w-11/12 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg py-3 px-4 */
  background-color: rgba(125, 125, 125, 0.525);
  position: relative;
  width: 100%;
  max-width: 800px;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  animation: scale-in 0.5s forwards;
  /* max-height: 700px;
  overflow-y: auto;
  height: 100%; */
}
