.container {
  height: 100%;
  min-height: 80vh;
  width: 100%;
  background-image: url(../../../public/assets/bg.svg);
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  /* background: linear-gradient(to right, #9900ff, #cc80ff); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  text-align: center;
}
.innerBox {
  min-width: 450px;
  height: fit-content;
  width: fit-content;
  background: #1f2a40;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer .error {
  font-weight: bold;
  font-size: 0.875rem;
  color: #ff3300;
  text-align: center;
}

.footer button {
  outline: none;
  border: none;
  background-color: #9900ff;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 16px;
  width: 100%;
  transition: 100ms;
  cursor: pointer;
}

.footer button:disabled {
  background-color: gray !important;
}

.footer button:hover {
  background-color: #aa2aff;
}

.footer p {
  font-weight: 700;
}

.footer p span a {
  font-weight: bold;
  color: #9900ff;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
}

.containerIp {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerIp label {
  font-weight: 700;
  font-size: 1rem;
  /* color: #dfdfdf; */
}

.containerIp input {
  border-radius: 5px;
  border: 1px solid #dddddd;
  outline: none;
  padding: 10px 15px;
  color: #000;
}

.containerIp input:hover {
  border-color: #ccc;
}

.containerIp input:focus {
  border-color: #9900ff;
}
